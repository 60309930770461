<template lang="pug">
  .c-table.c-session-table
    .c-table__head.ed-grid.m-grid-16
      //- Header
      .m-cols-1 Nombres
      .m-cols-1 Apellidos
      .m-cols-1 Fecha
      .m-cols-1 Hora
      .m-cols-1 Edad
      .m-cols-1 Celular
      .m-cols-1 Dpto.
      .m-cols-1.center Distrito
      .m-cols-1 Inst. Edu.
      .m-cols-1 Exp.
      .m-cols-1 Cal.
      .m-cols-1 Pac. act.
      .m-cols-1 Pac. tot.
      .m-cols-1 Validación
      .m-cols-1 Ver perfil
      .m-cols-1
    .c-table__body
      //- Body
      psychologist-table-row(
        v-for="(psychologist, key) in S_PSYCHOLOGIST"
        :key="key"
        :psychologist="psychologist"
      )
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import component from '@/components/component.vue'

export default {
  components: {
    // component
    psychologistTableRow: () => import('@/components/02-molecule/psychologistTable/row')
  },
  props: {
    // propierty: { required: false, type: String },
  },

  data () {
    return {
      data: ''
    }
  },

  computed: {
    ...mapState({
      S_PSYCHOLOGIST: state => state.Psychologist.S_PSYCHOLOGIST
    })
  },
  watch: {},
  async created () {
    await this.A_GET_PSYCHOLOGIST()
  },
  mounted () {},
  updated () {},
  methods: {
    ...mapActions({
      A_GET_PSYCHOLOGIST: 'Psychologist/A_GET_PSYCHOLOGIST'
    })
  }
}
</script>

<style lang="sass" scoped>
</style>
